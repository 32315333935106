<template>
	<div class="row">
		<div class="col-sm-12">
			<form @submit.prevent="handleSubmit">
				<div class="row">
					<div class="form-group col-sm-12 col-md-12 required">
						<label for="account_id">Account:</label>
						<Select2
							name="account_id"
							v-model="account_id"
							:options="accountOptions"
							@change="onChangeAccount($event)"
							@select="onChangeAccount($event)"
						/>
					</div>
					<div class="form-group col-sm-12 col-md-12 required">
						<label for="vehicle_id">Vehicle:</label>
						<Select2
							name="vehicle_id"
							v-model.number="vehicle_id"
							:options="vehicleOptions"
							@change="onChangeVehicle($event)"
							@select="onChangeVehicle($event)"
						/>
					</div>
					<div class="form-group col-sm-12 col-md-6 required">
						<label for="from_date">From Date:</label>
						<input
							class="form-control"
							required
							type="date"
							v-model="from_date"
						/>
					</div>
					<TimeComponent
						:label="'From Time'"
						:selected_time="from_time"
						v-on:selected-time="setStart"
					/>
					<div class="form-group col-sm-12 col-md-6 required">
						<label for="to_date">To Date:</label>
						<input
							class="form-control"
							required
							type="date"
							v-model="to_date"
						/>
					</div>
					<TimeComponent
						:label="'To Time'"
						:selected_time="to_time"
						v-on:selected-time="setEnd"
					/>
					<div class="form-group col-sm-12">
						<input
							class="btn btn-primary"
							type="submit"
							value="Show"
							:disabled="this.onRequest"
						/>
					</div>
				</div>
			</form>
		</div>
		<div class="col-sm-12 col-md-12">
			<GMapMap
				:center="center"
				:zoom="14"
				:disableDefaultUI="true"
				:options="{
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: true,
					streetViewControl: false,
					rotateControl: true,
					fullscreenControl: true,
				}"
				ref="gmap"
				map-type-id="terrain"
				style="width: 100%; height: 50vh"
			>
				<GMapMarker :key="index" v-for="(m, index) in events" :position="m">
					<GMapInfoWindow
						:options="{
							maxWidth: 300,
						}"
						:position="m"
						:opened="true"
					>
						<div
							class="text-center"
							v-html="`<b>${m.message}</b><br/>` + getUtc(m.device_time)"
						></div>
					</GMapInfoWindow>
				</GMapMarker>
				<GMapPolyline
					v-if="history_vehicle && history_vehicle.length > 0"
					:path="history_vehicle"
					:options="{ strokeColor: '#0000FF', strokeWeight: 2 }"
				/>
				<GMapMarker
					v-if="selectedData"
					:position="this.selectedData"
					ref="selectedMarker"
					:icon="this.icon"
					:draggable="false"
					@click="clickVehicle(this.selectedData)"
				/>
				<MapStopComponent
					v-for="(m, index) in routeData"
					:key="index"
					:gmap="this.$refs.gmap"
					:stop="m.stop"
				/>
			</GMapMap>
		</div>
		<div class="col-sm-12 col-md-12" v-if="history_vehicle.length > 0">
			<div class="row">
				<div class="form-group col-sm-12">
					<button class="btn btn-success mt-3" @click="play()">
						<i class="fas fa-play"></i>
					</button>
					<button class="btn btn-success mt-3 mx-3" @click="pause()">
						<i class="fas fa-pause"></i>
					</button>
					<button class="btn btn-success mt-3" @click="stop()">
						<i class="fas fa-stop"></i>
					</button>
					<select class="mx-3" name="speed_play" v-model.number="speed_play">
						<option value="1">x1</option>
						<option value="2">x2</option>
						<option value="3">X3</option>
						<option value="4">x4</option>
						<option value="5">x5</option>
						<option value="6">x6</option>
					</select>
					<input
						type="range"
						class="form-range"
						style="width:100%;"
						:min="0"
						:max="this.histories_moving.length - 1"
						:step="1"
						v-model="this.played_idx"
					/>
				</div>
			</div>
		</div>
		<div class="col-sm-12 mt-3">
			<Datatable
				v-bind:entries="history_vehicle"
				:columns="columns"
				:currEntries="100"
				:onRowClickEvent="onRowClicked"
				:title="title"
			/>
		</div>
		<div
			v-if="this.onRequest"
			class="modal-backdrop fade show"
			style="display:flex; align-items: center; justify-content: center;"
		>
			<img :src="require('../assets/loading.gif')" />
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import moment from "moment";
	import Datatable from "../components/Datatable.vue";
	import TimeComponent from "../components/TimeComponent.vue";
	import VueGoogleMaps from "@fawmi/vue-google-maps";
	import Select2 from "vue3-select2-component";
	import MapStopComponent from "../components/MapStopComponent.vue";
	export default {
		computed: {
			...mapState("accounts", ["accounts"]),
			...mapState("vehicles", ["vehicles"]),
			...mapState("routes", ["routes"]),
			...mapState("history_vehicles", ["history_vehicle", "onRequest"]),
			google: VueGoogleMaps,
			icon() {
				return {
					path:
						"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
					size: { width: 60, height: 90, f: "px", b: "px" },
					scale: 0.7,
					rotation: this.selectedData ? parseInt(this.selectedData.course) : 0,
					fillColor: this.selectedData ? this.getColor(this.selectedData) : "",
					fillOpacity: 1,
					anchor: new window.google.maps.Point(15, 25),
				};
			},
			title() {
				if (this.vehicleData !== null) {
					let veh = this.vehicleData;
					if (veh) {
						return (
							"History Vehicle - " +
							veh.vehicle_no +
							" - " +
							this.from_date +
							" " +
							this.from_time +
							" - " +
							this.to_date +
							" " +
							this.to_time
						);
					}
				}
				return "History Vehicle";
			},
		},
		data() {
			return {
				speed_play: 1,
				timer: null,
				played_idx: 0,
				vehicle_id: null,
				account_id: null,
				from_date: moment().format("YYYY-MM-DD"),
				from_time: "00:00",
				to_date: moment().format("YYYY-MM-DD"),
				to_time: "23:59",
				infowindow: null,
				columns: [
					{
						name: "device_time",
						text: "Device Time",
						order: "desc",
						raw: {
							type: "Moment_UTC",
						},
					},
					{
						name: "server_time",
						text: "Server Time",
						raw: {
							type: "Moment_UTC",
						},
					},
					{
						name: "status",
						text: "Status",
						raw: {
							type: "Text",
							fields: [
								{ value: "1", text: "Drive" },
								{ value: "2", text: "Stop" },
								{ value: "5", text: "Event" },
							],
						},
					},
					{ name: "lat", text: "Latitude" },
					{ name: "lng", text: "Longitude" },
					{ name: "altitude", text: "Altitude" },
					{ name: "course", text: "Course" },
					{ name: "speed", text: "Speed" },
					{ name: "distance", text: "Distance" },
					{ name: "message", text: "Event" },
				],
				center: { lat: -6.21462, lng: 106.84513 },
				isFitOnce: false,
				events: [],
				selectedData: null,
				histories_moving: [],
				vehicleOptions: [],
				accountOptions: [],
				routeData: [],
				vehicleData: null,
			};
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				this.vehicleData = null;
				this.events = [];
				this.isFitOnce = false;
				this.selectedData = null;
				clearInterval(this.timer);
				this.clear();
				const { vehicle_id, from_date, from_time, to_date, to_time } = this;
				let day = moment(this.from_date).diff(moment(this.to_date), "days");
				if (day > 0) {
					this.error("Please Input Correct Dates.");
					return;
				}

				// if(day < -3){
				//   this.error('Maximum Date Range is 3 days')
				//   return;
				// }
				let from_date_s = moment(from_date + " " + from_time)
					.utc()
					.format("YYYY-MM-DD");
				let from_time_s = moment(from_date + " " + from_time)
					.utc()
					.format("HH:mm");
				let to_date_s = moment(to_date + " " + to_time)
					.utc()
					.format("YYYY-MM-DD");
				let to_time_s = moment(to_date + " " + to_time)
					.utc()
					.format("HH:mm");
				let veh = this.vehicles.filter((item) => item.id === vehicle_id);
				if (veh.length > 0) {
					this.vehicleData = veh[0];
				}
				this.get_data({
					vehicle_id,
					from_date: from_date_s,
					from_time: from_time_s,
					to_date: to_date_s,
					to_time: to_time_s,
				});
			},
			...mapActions("accounts", ["get_all"]),
			...mapActions("vehicles", { getRoutes: "get_vehicle_by_account" }),
			...mapActions("routes", { getByVehicles: "get_route_by_vehicle" }),
			...mapActions("history_vehicles", ["get_data", "clear_data"]),
			...mapActions("alert", ["error", "clear"]),
			onChangeAccount(event) {
				this.getRoutes({ account_id: parseInt(event.id) });
				this.vehicle_id = null;
				this.vehicleData = null;
				this.routeData = [];
			},
			onChangeVehicle(event) {
				this.getByVehicles({ vehicle_id: parseInt(event.id) });
			},
			setStart(val) {
				this.from_time = val;
			},
			setEnd(val) {
				this.to_time = val;
			},
			onRowClicked(item) {
				this.selectedData = item;
				this.clickVehicle(item);
				if (this.$refs.selectedMarker) {
					this.$refs.selectedMarker.$markerObject.setPosition(item);
					this.$refs.gmap.$mapPromise.then(() => {
						this.$refs.gmap.panTo(
							this.$refs.selectedMarker.$markerObject.getPosition()
						);
					});
				}
			},
			getColor(data) {
				if (data.speed > 0) {
					return "#3bb143";
				}
				return "#ef5350";
			},
			play() {
				clearInterval(this.timer);
				const self = this;
				this.timer = setInterval(function() {
					let item = self.histories_moving[self.played_idx];
					self.onRowClicked(item);
					if (self.played_idx < self.histories_moving.length) {
						self.played_idx++;
					}
				}, 1000 / self.speed_play);
			},
			pause() {
				clearInterval(this.timer);
			},
			stop() {
				this.played_idx = 0;
				this.selectedData = null;
				clearInterval(this.timer);
			},
			getUtc(item) {
				return moment
					.utc(item, "YYYY-MM-DD HH:mm:ss")
					.local()
					.format("YYYY-MM-DD HH:mm:ss");
			},
			clickVehicle(selectedData) {
				if (!selectedData) {
					return;
				}
				const self = this;
				let veh = this.vehicleData;
				this.$refs.gmap.$mapPromise.then(() => {
					this.$nextTick().then(() => {
						if (self.infowindow !== null) {
							self.infowindow.setMap(null);
							self.infowindow = null;
						}
						self.infowindow = new window.google.maps.InfoWindow({
							content:
								"<table>" +
								"<tr><th>Vehicle No</th><td>:</td><td>" +
								veh.vehicle_no +
								"</td></tr>" +
								"<tr><th>Vehicle Code</th><td>:</td><td>" +
								veh.vehicle_code +
								"</td></tr>" +
								"<tr><th>Speed</th><td>:</td><td>" +
								selectedData.speed +
								" km/h</td></tr>" +
								"<tr><th>Status</th><td>:</td><td>" +
								(selectedData.speed > 0 ? "Drive" : "Stop") +
								"</td></tr>" +
								"<tr><th>Last Update</th><td>:</td><td>" +
								moment
									.utc(selectedData.device_time)
									.local()
									.format("lll") +
								"</td></tr>" +
								"</table>",
							maxWidth: 300,
						});
						self.infowindow.open({
							map: self.$refs.gmap.$mapObject,
							anchor: self.$refs.selectedMarker.$markerObject,
						});
					});
				});
			},
		},
		created() {
			this.$emit("onChildInit", "Report History Vehicle");
			this.clear_data();
			this.get_all();
		},
		unmounted() {
			clearInterval(this.timer);
		},
		components: {
			Datatable,
			TimeComponent,
			Select2,
			MapStopComponent,
		},
		watch: {
			routes(nextState, prevState) {
				if (nextState !== prevState) {
					this.routeData = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							if (item.route_start)
								this.routeData.push({ stop: item.route_start });
							if (item.route_end) this.routeData.push({ stop: item.route_end });
						});
					}
					return;
				}
			},
			vehicles(nextState, prevState) {
				if (nextState !== prevState) {
					this.vehicleOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.vehicleOptions.push({
								id: item.id,
								text: item.vehicle_no + " - " + item.vehicle_code,
							});
						});
					}
					return;
				}
			},
			accounts(nextState, prevState) {
				if (nextState !== prevState) {
					this.accountOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.accountOptions.push({
								id: item.id,
								text: item.account_name,
							});
						});
					}
					return;
				}
			},
			history_vehicle(nextState, prevState) {
				if (nextState !== prevState) {
					if (nextState.length > 0) {
						nextState.sort(function(a, b) {
							if (a.device_time < b.device_time) return -1;
							else return 1;
						});
						this.events = nextState.filter((item) => item.status == 5);
						if (!this.isFitOnce) {
							this.$refs.gmap.$mapPromise.then(() => {
								let bounds = new window.google.maps.LatLngBounds();
								nextState.forEach((loc) => {
									bounds.extend(loc);
								});
								this.$nextTick().then(() => {
									this.$refs.gmap.fitBounds(bounds);
								});
								this.$refs.gmap.panToBounds(bounds, 100);
							});
							this.isFitOnce = true;
						}
						this.histories_moving = nextState.filter((item) => item.speed > 0);
					}
					return;
				}
			},
		},
	};
</script>
