<template>
	<div class="row">
		<div class="col-sm-12">
			<form @submit.prevent="handleSubmit">
				<div class="row">
					<div class="form-group col-sm-12 col-md-12 required">
						<label for="account_id">Account:</label>
						<Select2
							name="account_id"
							v-model="account_id"
							:options="accountOptions"
							@change="onChangeAccount($event)"
							@select="onChangeAccount($event)"
						/>
					</div>
					<div class="form-group col-sm-12 col-md-12 required">
						<label for="vehicle_id">Vehicle:</label>
						<Select2
							name="vehicle_id"
							v-model.number="vehicle_id"
							:options="vehicleOptions"
						/>
					</div>
					<div class="form-group col-sm-12 col-md-6 required">
						<label for="from_date">From Date:</label>
						<input
							class="form-control"
							required
							type="date"
							v-model="from_date"
						/>
					</div>
					<TimeComponent
						:label="'From Time'"
						:selected_time="from_time"
						v-on:selected-time="setStart"
					/>
					<div class="form-group col-sm-12 col-md-6 required">
						<label for="to_date">To Date:</label>
						<input
							class="form-control"
							required
							type="date"
							v-model="to_date"
						/>
					</div>
					<TimeComponent
						:label="'To Time'"
						:selected_time="to_time"
						v-on:selected-time="setEnd"
					/>
					<div class="form-group col-sm-12">
						<input
							class="btn btn-primary"
							type="submit"
							value="Show"
							:disabled="this.onRequest"
						/>
					</div>
				</div>
			</form>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="total_distance">Total Distance:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="total_distance"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="total_duration">Total Duration:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="total_duration"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="total_driving_time">Total Driving Time:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="total_driving_time"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="total_idle_time">Total Idle Time:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="total_idle_time"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="total_distance">Top Speed:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="top_speed"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="total_driving_time">Average Speed:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="average_speed"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="fuel_consumption_gps">Fuel Consumption:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="fuel_consumption_gps"
			/>
		</div>
		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
			<label for="fuel_price_gps">Fuel Cost:</label>
			<input
				class="form-control text-right"
				disabled
				type="text"
				v-model="fuel_price_gps"
			/>
		</div>
		<div v-for="data in data_sensors" :key="data" class="col-sm-12 mt-3">
			<LineChart :label="data.name" :data="data.data" :uom="data.uom" />
		</div>
		<div
			v-if="this.onRequest"
			class="modal-backdrop fade show"
			style="display:flex; align-items: center; justify-content: center;"
		>
			<img :src="require('../assets/loading.gif')" />
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import moment from "moment";
	import TimeComponent from "../components/TimeComponent.vue";
	import Select2 from "vue3-select2-component";
	import LineChart from "../components/LineChart.vue";
	export default {
		computed: {
			...mapState("accounts", ["accounts"]),
			...mapState("vehicles", ["vehicles"]),
			...mapState("drivers", ["drivers"]),
			...mapState("journey_vehicles", ["journey_vehicle"]),
			...mapState("report_graph_sensors", ["report_graph_sensor", "onRequest"]),
		},
		data() {
			return {
				vehicle_id: "",
				account_id: "",
				total_distance: "",
				total_idle_time: "",
				total_driving_time: "",
				total_duration: "",
				top_speed: "",
				average_speed: "",
				fuel_consumption_gps: "",
				fuel_price_gps: "",
				from_date: moment().format("YYYY-MM-DD"),
				from_time: "00:00",
				to_date: moment().format("YYYY-MM-DD"),
				to_time: "23:59",
				columns: false,
				vehicleOptions: [],
				accountOptions: [],
				data_sensors: [],
			};
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				this.clear();
				const { vehicle_id, from_date, from_time, to_date, to_time } = this;
				let day = moment(this.from_date).diff(moment(this.to_date), "days");
				if (day > 0) {
					this.error("Please Input Correct Dates.");
					return;
				}

				// if(day < -3){
				//   this.error('Maximum Date Range is 3 days')
				//   return;
				// }
				let from_date_s = moment(from_date + " " + from_time)
					.utc()
					.format("YYYY-MM-DD");
				let from_time_s = moment(from_date + " " + from_time)
					.utc()
					.format("HH:mm");
				let to_date_s = moment(to_date + " " + to_time)
					.utc()
					.format("YYYY-MM-DD");
				let to_time_s = moment(to_date + " " + to_time)
					.utc()
					.format("HH:mm");

				this.get_data({
					vehicle_id,
					from_date: from_date_s,
					from_time: from_time_s,
					to_date: to_date_s,
					to_time: to_time_s,
				});
				this.getJourneyVehicle({
					vehicle_id,
					from_date: from_date_s,
					from_time: from_time_s,
					to_date: to_date_s,
					to_time: to_time_s,
				});
			},
			...mapActions("accounts", ["get_all"]),
			...mapActions("drivers", { getDrivers: "get_driver_by_account" }),
			...mapActions("vehicles", { getVehicles: "get_vehicle_by_account" }),
			...mapActions("report_graph_sensors", ["get_data", "clear_data"]),
			...mapActions("journey_vehicles", { getJourneyVehicle: "get_data" }),
			...mapActions("alert", ["error", "clear"]),
			onChangeAccount(event) {
				this.getVehicles({ account_id: parseInt(event.id) });
				this.getDrivers({ account_id: parseInt(event.id) });
				this.vehicle_id = "";
			},
			setStart(val) {
				this.from_time = val;
			},
			setEnd(val) {
				this.to_time = val;
			},
			calculateTotalDuration(stopDuration, driveDuration) {
				// Convert stopDuration to seconds
				const stopParts = stopDuration.split(" ");
				let stopSeconds = 0;
				for (const part of stopParts) {
					if (part.endsWith("h")) {
						stopSeconds += parseInt(part.slice(0, -1)) * 3600;
					} else if (part.endsWith("min")) {
						stopSeconds += parseInt(part.slice(0, -3)) * 60;
					} else if (part.endsWith("s")) {
						stopSeconds += parseInt(part.slice(0, -1));
					}
				}

				// Convert driveDuration to seconds
				const driveParts = driveDuration.split(" ");
				let driveSeconds = 0;
				for (const part of driveParts) {
					if (part.endsWith("h")) {
						driveSeconds += parseInt(part.slice(0, -1)) * 3600;
					} else if (part.endsWith("min")) {
						driveSeconds += parseInt(part.slice(0, -3)) * 60;
					} else if (part.endsWith("s")) {
						driveSeconds += parseInt(part.slice(0, -1));
					}
				}

				// Calculate total duration in seconds
				const totalSeconds = stopSeconds + driveSeconds;

				// Format the total duration as h:min:s
				const hours = Math.floor(totalSeconds / 3600);
				const minutes = Math.floor((totalSeconds % 3600) / 60);
				const seconds = totalSeconds % 60;

				return `${hours}h ${minutes}min ${seconds}s`;
			},
		},
		created() {
			this.$emit("onChildInit", "Report Graph Sensor");
			this.clear_data();
			this.get_all();
		},
		components: {
			TimeComponent,
			Select2,
			LineChart,
		},
		watch: {
			vehicles(nextState, prevState) {
				if (nextState !== prevState) {
					this.vehicleOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.vehicleOptions.push({
								id: item.id,
								text: item.vehicle_no + " - " + item.vehicle_code,
							});
						});
					}
					return;
				}
			},
			accounts(nextState, prevState) {
				if (nextState !== prevState) {
					this.accountOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.accountOptions.push({
								id: item.id,
								text: item.account_name,
							});
						});
					}
					return;
				}
			},
			report_graph_sensor(nextState, prevState) {
				if (nextState !== prevState) {
					if (nextState.sensor_data && nextState.sensor_data.length > 0) {
						let sensors = [];
						const clientTimeZoneOffset = moment().utcOffset();
						for (let i = 0; i < nextState.sensor_data.length; i++) {
							for (let j = 0; j < nextState.sensors.length; j++) {
								if (
									nextState.sensors[j].name &&
									nextState.sensors[j].name.split(" ")[0] ==
										nextState.sensor_data[i].sensor_name
								) {
									const snts = sensors.findIndex(
										(item) => item.key === nextState.sensors[j].key
									);
									if (snts < 0) {
										sensors.push({
											name: nextState.sensors[j].name,
											key: nextState.sensors[j].key,
											uom: nextState.sensor_data[i].unit_of_measurement,
											data: [],
										});
										break;
									}
								}
							}
						}
						for (let i = 0; i < sensors.length; i++) {
							let data = [];
							nextState.data.map((item) => {
								const datetime = moment(item.t).format("DD-MM-YYYY HH:mm:ss");
								const itemdata = {
									data: parseFloat(item[sensors[i].key]),
									label: moment
										.utc(item.t)
										.utcOffset(clientTimeZoneOffset)
										.format("DD-MM-YYYY HH:mm"),
								};
								const hour = datetime.split(" ")[1].split(":")[0];
								const tgl = datetime.split(" ")[0];
								// const check = moment(item.t).isBetween(
								// 	moment(this.from_date + " " + this.from_time),
								// 	moment(this.to_date + " " + this.to_time)
								// );
								if (/* check && */ !data[tgl + hour.toString()]) {
									data[tgl + hour.toString()] = itemdata;
								}
								// data.push(itemdata);
							});
							sensors[i].data = Object.values(data);
							// sensors[i].data = data;
						}
						this.data_sensors = sensors;
					}
					return;
				}
			},
			journey_vehicle(nextState, prevState) {
				if (nextState !== prevState) {
					if (nextState.length > 0) {
						let total_distance = 0;
						let total_idle_time = 0;
						let total_driving_time = 0;
						let top_speed = 0;
						let average_speed = 0;
						let fuel_consumption_gps = 0;
						let fuel_price_gps = 0;
						nextState.map((item) => {
							if (item.status == 4) {
								total_distance = item.distance;
								total_idle_time = item.stop_duration;
								total_driving_time = item.drive_duration;
								total_driving_time = item.drive_duration;
								top_speed = item.top_speed;
								average_speed = item.average_speed;
								fuel_consumption_gps = item.fuel_consumption_gps;
								fuel_price_gps = item.fuel_price_gps;
							}
						});
						this.top_speed = top_speed;
						this.average_speed = average_speed;
						this.total_distance = total_distance;
						this.total_idle_time = total_idle_time;
						this.total_driving_time = total_driving_time;
						this.fuel_consumption_gps = fuel_consumption_gps;
						this.fuel_price_gps = fuel_price_gps;

						this.total_duration = this.calculateTotalDuration(
							total_driving_time,
							total_idle_time
						);
					}
				}
			},
		},
	};
</script>
