<template>
	<div class="row">
		<div class="col-12">
			<div class="row">
				<div class="col-12 col-md-6">
					<form @submit.prevent="handleSubmit">
						<div class="row">
							<div class="form-group col-sm-12 col-md-12 required">
								<label for="account_id">Account:</label>
								<Select2
									name="account_id"
									v-model="account_id"
									:options="accountOptions"
									@change="onChangeAccount($event)"
									@select="onChangeAccount($event)"
								/>
							</div>
							<div class="form-group col-sm-12 col-md-12 required">
								<label for="vehicle_id">Vehicle:</label>
								<Select2
									name="vehicle_id"
									v-model.number="vehicle_id"
									:options="vehicleOptions"
								/>
							</div>
							<div class="form-group col-sm-12 col-md-6 required">
								<label for="from_date">From Date:</label>
								<input
									class="form-control"
									required
									type="date"
									v-model="from_date"
								/>
							</div>
							<TimeComponent
								:label="'From Time'"
								:selected_time="from_time"
								v-on:selected-time="setStart"
							/>
							<div class="form-group col-sm-12 col-md-6 required">
								<label for="to_date">To Date:</label>
								<input
									class="form-control"
									required
									type="date"
									v-model="to_date"
								/>
							</div>
							<TimeComponent
								:label="'To Time'"
								:selected_time="to_time"
								v-on:selected-time="setEnd"
							/>
							<div class="form-group col-sm-12 col-md-6 required">
								<label for="stops">Stop Duration:</label>
								<Select2 name="stops" v-model="stops" :options="stopOptions" />
							</div>
							<div class="form-group col-sm-12">
								<input
									class="btn btn-primary"
									type="submit"
									value="Show"
									:disabled="this.onRequest"
								/>
							</div>
						</div>
					</form>
				</div>
				<div class="col-12 col-md-6">
					<div class="col-12">
						<h5><strong>History Vehicle</strong></h5>
					</div>
					<div class="col-sm-12 col-md-12">
						<GMapMap
							:center="center"
							:zoom="14"
							:disableDefaultUI="true"
							:options="{
								zoomControl: false,
								mapTypeControl: false,
								scaleControl: true,
								streetViewControl: false,
								rotateControl: true,
								fullscreenControl: true,
							}"
							ref="gmap"
							map-type-id="terrain"
							style="width: 100%; height: 50vh"
						>
							<GMapMarker
								:key="index"
								v-for="(m, index) in events"
								:position="m"
							>
								<GMapInfoWindow
									:options="{
										maxWidth: 300,
									}"
									:position="m"
									:opened="true"
								>
									<div
										class="text-center"
										v-html="`<b>${m.message}</b><br/>` + getUtc(m.device_time)"
									></div>
								</GMapInfoWindow>
							</GMapMarker>
							<GMapPolyline
								v-if="history_vehicle && history_vehicle.length > 0"
								:path="history_vehicle"
								:options="{ strokeColor: '#0000FF', strokeWeight: 2 }"
							/>
							<GMapMarker
								v-if="selectedData"
								:position="this.selectedData"
								ref="selectedMarker"
								:icon="this.icon"
								:draggable="false"
								@click="clickVehicle(this.selectedData)"
							/>
							<MapStopComponent
								v-for="(m, index) in routeData"
								:key="index"
								:gmap="this.$refs.gmap"
								:stop="m.stop"
							/>
						</GMapMap>
					</div>
					<div class="col-sm-12 col-md-12" v-if="history_vehicle.length > 0">
						<div class="row">
							<div class="form-group col-sm-12">
								<button
									class="btn btn-success mt-3"
									:class="{ disabled: status_play === 'play' }"
									@click="play()"
								>
									<i class="fas fa-play"></i>
								</button>
								<button
									class="btn btn-success mt-3 mx-3"
									:class="{
										disabled: status_play === 'pause' || status_play === 'stop',
									}"
									@click="pause()"
								>
									<i class="fas fa-pause"></i>
								</button>
								<button
									class="btn btn-success mt-3"
									:class="{ disabled: status_play === 'stop' }"
									@click="stop()"
								>
									<i class="fas fa-stop"></i>
								</button>
								<select
									class="mx-3"
									name="speed_play"
									v-model.number="speed_play"
								>
									<option value="1">x1</option>
									<option value="2">x2</option>
									<option value="3">X3</option>
									<option value="4">x4</option>
									<option value="5">x5</option>
									<option value="6">x6</option>
								</select>
								<input
									type="range"
									class="form-range"
									style="width:100%;"
									:min="0"
									:max="this.histories_moving.length - 1"
									:step="1"
									v-model="this.played_idx"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="journey_vehicle && journey_vehicle.length > 0"
			class="col-sm-12 mb-3"
		>
			<div class="row">
				<div class="col-sm-2">
					<label for="total_distance">Total Distance:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="total_distance"
					/>
				</div>
				<div class="col-sm-2">
					<label for="total_driving_time">Total Driving Time:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="total_driving_time"
					/>
				</div>
				<div class="col-sm-2">
					<label for="total_idle_time">Total Idle Time:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="total_idle_time"
					/>
				</div>
				<div class="col-sm-2">
					<label for="fuel_consumption_gps">Fuel Consumption:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="fuel_consumption_gps"
					/>
				</div>
				<div class="col-sm-2">
					<label for="total_distance">Top Speed:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="top_speed"
					/>
				</div>
			</div>
		</div>

		<div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-12">
			<div class="row">
				<div class="col-sm-2">
					<label for="total_duration">Distance / Day:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="distance_day"
					/>
				</div>
				<div class="col-sm-2">
					<label for="total_duration">Driving Time / Day:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="driving_by_day"
					/>
				</div>
				<div class="col-sm-2">
					<label for="total_duration">Total Duration:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="total_duration"
					/>
				</div>
				<div class="col-sm-2">
					<label for="fuel_price_gps">Km/Liter:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="km_liter"
					/>
				</div>
				<div class="col-sm-2">
					<label for="total_driving_time">Average Speed:</label>
					<input
						class="form-control text-right"
						disabled
						type="text"
						v-model="average_speed"
					/>
				</div>
			</div>
		</div>

		<div class="col-sm-12 mt-3">
			<h3 v-if="journey_vehicle && journey_vehicle.length > 0">{{ title }}</h3>
			<Datatable
				v-bind:entries="data_journey"
				:columns="columns"
				:currEntries="100"
				:title="title"
			/>
		</div>
		<div
			v-if="this.onRequest"
			class="modal-backdrop fade show"
			style="display:flex; align-items: center; justify-content: center;"
		>
			<img :src="require('../assets/loading.gif')" />
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import moment from "moment";
	import Datatable from "../components/Datatable.vue";
	import TimeComponent from "../components/TimeComponent.vue";
	import VueGoogleMaps from "@fawmi/vue-google-maps";
	import MapStopComponent from "../components/MapStopComponent.vue";
	import Select2 from "vue3-select2-component";
	export default {
		computed: {
			...mapState("accounts", ["accounts"]),
			...mapState("vehicles", ["vehicles"]),
			...mapState("journey_vehicles", ["journey_vehicle", "onRequest"]),
			...mapState("history_vehicles", ["history_vehicle", "onRequest"]),
			google: VueGoogleMaps,
			icon() {
				return {
					path:
						"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
					size: { width: 60, height: 90, f: "px", b: "px" },
					scale: 0.7,
					rotation: this.selectedData ? parseInt(this.selectedData.course) : 0,
					fillColor: this.selectedData ? this.getColor(this.selectedData) : "",
					fillOpacity: 1,
					anchor: new window.google.maps.Point(15, 25),
				};
			},
			title() {
				if (this.vehicle_id !== null) {
					let veh = this.vehicles.filter((item) => item.id === this.vehicle_id);
					if (veh.length > 0) {
						return (
							"Journey Vehicle - " +
							veh[0].vehicle_no +
							" - " +
							this.from_date +
							" " +
							this.from_time +
							" - " +
							this.to_date +
							" " +
							this.to_time
						);
					}
				}
				return "Journey Vehicle";
			},
			data_journey() {
				return this.journey_vehicle;
			},
			total_day() {
				const days = moment(this.to_date).diff(moment(this.from_date), "days");
				return days + 1;
			},
			distance_day() {
				let dd = 0;
				const td = this.total_distance.split(" ")[0];
				if (td !== undefined) {
					const convert_td = parseFloat(td);
					if (convert_td > 0) {
						dd = convert_td / this.total_day;
						// round to 2 decimal
						dd = Math.round((dd + Number.EPSILON) * 100) / 100;
					}
				}

				return dd + " km";
			},
			driving_by_day() {
				let result = "";
				const convertSecond = this.timeStringToSeconds(this.total_driving_time);
				if (this.total_day > 0) {
					const timeDaySecond = convertSecond / this.total_day;
					result = this.secondsToTimeString(timeDaySecond);
				}

				return result;
			},
			km_liter() {
				const fuelString = this.fuel_consumption_gps.split(" ")[0];
				const distanceString = this.total_distance.split(" ")[0];
				if (fuelString !== undefined && distanceString !== undefined) {
					const fuel = parseFloat(fuelString);
					const distance = parseFloat(distanceString);
					if (fuel > 0 && distance > 0) {
						return `${(fuel / distance).toFixed(2)} L/Km`;
					}
				}

				return "0 L/Km";
			},
		},
		data() {
			return {
				vehicle_id: null,
				account_id: null,
				from_date: moment().format("YYYY-MM-DD"),
				from_time: "00:00",
				to_date: moment().format("YYYY-MM-DD"),
				to_time: "23:59",
				total_distance: "",
				total_idle_time: "",
				total_driving_time: "",
				total_duration: "",
				stops: "180",
				top_speed: "",
				average_speed: "",
				fuel_consumption_gps: "",
				fuel_price_gps: "",
				vehicleOptions: [],
				accountOptions: [],
				stopOptions: [
					{ id: "60", text: "1 min" },
					{ id: "120", text: "2 min" },
					{ id: "180", text: "3 min" },
					{ id: "300", text: "5 min" },
					{ id: "600", text: "10 min" },
					{ id: "900", text: "15 min" },
					{ id: "1200", text: "20 min" },
					{ id: "1800", text: "30 min" },
					{ id: "3600", text: "1 hr" },
					{ id: "7200", text: "2 hr" },
					{ id: "10800", text: "3 hr" },
					{ id: "21600", text: "6 hr" },
				],
				columns: [
					{
						name: "start",
						text: "Start Time",
						order: "asc",
						raw: {
							type: "Moment_UTC",
						},
					},
					{
						name: "end",
						text: "End Time",
						raw: {
							type: "Moment_UTC",
						},
					},
					{ name: "start_location", text: "Start Location" },
					{ name: "end_location", text: "End Location" },
					{ name: "duration", text: "Duration" },
					{ name: "distance", text: "Distance" },
					{ name: "top_speed", text: "Top Speed" },
					{ name: "average_speed", text: "Average Speed" },
					{ name: "fuel_consumption_gps", text: "Fuel Consumption (GPS)" },
					{ name: "fuel_price_gps", text: "Fuel Cost (GPS)" },
				],
				// for maps
				speed_play: 1,
				timer: null,
				played_idx: 0,
				center: { lat: -6.21462, lng: 106.84513 },
				isFitOnce: false,
				events: [],
				routeData: [],
				infowindow: null,
				vehicleData: null,
				selectedData: null,
				status_play: "stop",
			};
		},
		components: {
			Datatable,
			TimeComponent,
			Select2,
			MapStopComponent,
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				this.vehicleData = null;
				this.isFitOnce = false;
				this.selectedData = null;
				this.events = [];
				this.clear();
				const {
					vehicle_id,
					from_date,
					from_time,
					to_date,
					to_time,
					stops,
				} = this;
				let day = moment(this.from_date).diff(moment(this.to_date), "days");
				if (day > 0) {
					this.error("Please Input Correct Dates.");
					return;
				}

				// if(day < -3){
				//   this.error('Maximum Date Range is 3 days')
				//   return;
				// }
				let from_date_s = moment(from_date + " " + from_time)
					.utc()
					.format("YYYY-MM-DD");
				let from_time_s = moment(from_date + " " + from_time)
					.utc()
					.format("HH:mm");
				let to_date_s = moment(to_date + " " + to_time)
					.utc()
					.format("YYYY-MM-DD");
				let to_time_s = moment(to_date + " " + to_time)
					.utc()
					.format("HH:mm");

				let veh = this.vehicles.filter((item) => item.id === vehicle_id);
				if (veh.length > 0) {
					this.vehicleData = veh[0];
				}

				this.get_data({
					vehicle_id,
					stops,
					from_date: from_date_s,
					from_time: from_time_s,
					to_date: to_date_s,
					to_time: to_time_s,
				});
				this.getHistoryData({
					vehicle_id,
					from_date: from_date_s,
					from_time: from_time_s,
					to_date: to_date_s,
					to_time: to_time_s,
				});
			},
			...mapActions("accounts", ["get_all"]),
			...mapActions("vehicles", { getRoutes: "get_vehicle_by_account" }),
			...mapActions("journey_vehicles", ["get_data", "clear_data"]),
			...mapActions("alert", ["error", "clear"]),
			...mapActions("routes", { getByVehicles: "get_route_by_vehicle" }),
			...mapActions("history_vehicles", ["clear_data"]),
			...mapActions({ getHistoryData: "history_vehicles/get_data" }),
			onChangeAccount(event) {
				this.getRoutes({ account_id: parseInt(event.id) });
				this.vehicle_id = null;
				this.vehicleData = null;
				this.routeData = [];
			},
			onChangeVehicle(event) {
				this.getByVehicles({ vehicle_id: parseInt(event.id) });
			},
			setStart(val) {
				this.from_time = val;
			},
			setEnd(val) {
				this.to_time = val;
			},
			calculateTotalDuration(stopDuration, driveDuration) {
				// Convert stopDuration to seconds
				const stopParts = stopDuration.split(" ");
				let stopSeconds = 0;
				for (const part of stopParts) {
					if (part.endsWith("h")) {
						stopSeconds += parseInt(part.slice(0, -1)) * 3600;
					} else if (part.endsWith("min")) {
						stopSeconds += parseInt(part.slice(0, -3)) * 60;
					} else if (part.endsWith("s")) {
						stopSeconds += parseInt(part.slice(0, -1));
					}
				}

				// Convert driveDuration to seconds
				const driveParts = driveDuration.split(" ");
				let driveSeconds = 0;
				for (const part of driveParts) {
					if (part.endsWith("h")) {
						driveSeconds += parseInt(part.slice(0, -1)) * 3600;
					} else if (part.endsWith("min")) {
						driveSeconds += parseInt(part.slice(0, -3)) * 60;
					} else if (part.endsWith("s")) {
						driveSeconds += parseInt(part.slice(0, -1));
					}
				}

				// Calculate total duration in seconds
				const totalSeconds = stopSeconds + driveSeconds;

				// Format the total duration as h:min:s
				const hours = Math.floor(totalSeconds / 3600);
				const minutes = Math.floor((totalSeconds % 3600) / 60);
				const seconds = totalSeconds % 60;

				return `${hours.toFixed(0)}h ${minutes.toFixed(0)}min ${seconds.toFixed(
					0
				)}s`;
			},
			onRowClicked(item) {
				this.selectedData = item;
				this.clickVehicle(item);
				if (this.$refs.selectedMarker) {
					this.$refs.selectedMarker.$markerObject.setPosition(item);
					/* this.$refs.gmap.$mapPromise.then(() => {
						this.$refs.gmap.panTo(
							this.$refs.selectedMarker.$markerObject.getPosition()
						);
					}); */
				}
			},
			play() {
				clearInterval(this.timer);
				const self = this;
				this.timer = setInterval(function() {
					let item = self.histories_moving[self.played_idx];
					self.onRowClicked(item);
					if (self.played_idx < self.histories_moving.length) {
						self.played_idx++;
					}
					if (self.played_idx >= self.histories_moving.length) {
						self.stop();
					}
				}, 1000 / self.speed_play);
				this.status_play = "play";
			},
			pause() {
				this.status_play = "pause";
				clearInterval(this.timer);
			},
			stop() {
				this.played_idx = 0;
				this.selectedData = null;
				this.status_play = "stop";
				clearInterval(this.timer);
			},
			getColor(data) {
				if (data.speed > 0) {
					return "#3bb143";
				}
				return "#ef5350";
			},
			getUtc(item) {
				return moment
					.utc(item, "YYYY-MM-DD HH:mm:ss")
					.local()
					.format("YYYY-MM-DD HH:mm:ss");
			},
			clickVehicle(selectedData) {
				if (!selectedData) {
					return;
				}
				const self = this;
				let veh = this.vehicleData;
				this.$refs.gmap.$mapPromise.then(() => {
					this.$nextTick().then(() => {
						if (self.infowindow !== null) {
							self.infowindow.setMap(null);
							self.infowindow = null;
						}
						self.infowindow = new window.google.maps.InfoWindow({
							content:
								"<table>" +
								"<tr><th>Vehicle No</th><td>:</td><td>" +
								veh.vehicle_no +
								"</td></tr>" +
								"<tr><th>Vehicle Code</th><td>:</td><td>" +
								veh.vehicle_code +
								"</td></tr>" +
								"<tr><th>Speed</th><td>:</td><td>" +
								selectedData.speed +
								" km/h</td></tr>" +
								"<tr><th>Status</th><td>:</td><td>" +
								(selectedData.speed > 0 ? "Drive" : "Stop") +
								"</td></tr>" +
								"<tr><th>Last Update</th><td>:</td><td>" +
								moment
									.utc(selectedData.device_time)
									.local()
									.format("lll") +
								"</td></tr>" +
								"</table>",
							maxWidth: 300,
						});
						self.infowindow.open({
							map: self.$refs.gmap.$mapObject,
							anchor: self.$refs.selectedMarker.$markerObject,
						});
					});
				});
			},
			timeStringToSeconds(timeString) {
				const timeParts = timeString.split(" ");
				let seconds = 0;

				for (let part of timeParts) {
					if (part.endsWith("h")) {
						seconds += parseInt(part.replace("h", "")) * 3600;
					} else if (part.endsWith("min")) {
						seconds += parseInt(part.replace("min", "")) * 60;
					} else if (part.endsWith("s")) {
						seconds += parseInt(part.replace("s", ""));
					}
				}

				return seconds;
			},
			secondsToTimeString(seconds) {
				const hours = Math.floor(seconds / 3600);
				const minutes = Math.floor((seconds % 3600) / 60);
				const secondsLeft = seconds % 60;

				return `${hours.toFixed(0)}h ${minutes.toFixed(
					0
				)}min ${secondsLeft.toFixed(0)}s`;
			},
		},
		created() {
			this.$emit("onChildInit", "Journey Vehicle History");
			this.clear_data();
			this.get_all();
		},
		mounted() {
			this.stop();
		},
		watch: {
			vehicles(nextState, prevState) {
				if (nextState !== prevState) {
					this.vehicleOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.vehicleOptions.push({
								id: item.id,
								text: item.vehicle_no + " - " + item.vehicle_code,
							});
						});
					}
					return;
				}
			},
			accounts(nextState, prevState) {
				if (nextState !== prevState) {
					this.accountOptions = [];
					if (nextState.length > 0) {
						nextState.map((item) => {
							this.accountOptions.push({
								id: item.id,
								text: item.account_name,
							});
						});
					}
					return;
				}
			},
			journey_vehicle(nextState, prevState) {
				if (nextState !== prevState) {
					if (nextState.length > 0) {
						let total_distance = 0;
						let total_idle_time = 0;
						let total_driving_time = 0;
						let top_speed = 0;
						let average_speed = 0;
						let fuel_consumption_gps = 0;
						let fuel_price_gps = 0;
						nextState.map((item) => {
							if (item.status == 4) {
								total_distance = item.distance;
								total_idle_time = item.stop_duration;
								total_driving_time = item.drive_duration;
								total_driving_time = item.drive_duration;
								top_speed = item.top_speed;
								average_speed = item.average_speed;
								fuel_consumption_gps = item.fuel_consumption_gps;
								fuel_price_gps = item.fuel_price_gps;
								item.duration = this.calculateTotalDuration(
									total_driving_time,
									total_idle_time
								);
							}
						});
						this.top_speed = top_speed;
						this.average_speed = average_speed;
						this.total_distance = total_distance;
						this.total_idle_time = total_idle_time;
						this.total_driving_time = total_driving_time;
						this.fuel_consumption_gps = fuel_consumption_gps;
						this.fuel_price_gps = fuel_price_gps;

						this.total_duration = this.calculateTotalDuration(
							total_driving_time,
							total_idle_time
						);
					}
				}
			},
			history_vehicle(nextState, prevState) {
				if (nextState !== prevState) {
					if (nextState.length > 0) {
						nextState.sort(function(a, b) {
							if (a.device_time < b.device_time) return -1;
							else return 1;
						});
						this.events = nextState.filter((item) => item.status == 5);
						if (!this.isFitOnce) {
							this.$refs.gmap.$mapPromise.then(() => {
								let bounds = new window.google.maps.LatLngBounds();
								nextState.forEach((loc) => {
									bounds.extend(loc);
								});
								this.$nextTick().then(() => {
									this.$refs.gmap.fitBounds(bounds);
								});
								this.$refs.gmap.panToBounds(bounds, 100);
							});
							this.isFitOnce = true;
						}
						this.histories_moving = nextState.filter((item) => item.speed > 0);
					}
					return;
				}
			},
		},
	};
</script>
