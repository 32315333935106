<template>
    <div class="error-page" style="height:50vh;align-items:center;display:flex;">
      <h2 class="headline text-warning"> 404</h2>

      <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>

        <p>
          We could not find the page you were looking for.<br/>
          Meanwhile, you may <router-link to="/">return to Home</router-link>.
        </p>
      </div>
      <!-- /.error-content -->
    </div>
</template>

<script>
  export default {
  }
</script>
